.campaign-step-wrapper .campaign-settings {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
}

.campaign-step-wrapper .setting-group .options-wrapper {
  display: flex;
  padding: 16px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 12px;
}

.campaign-step-wrapper .setting-group .options-wrapper label {
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
