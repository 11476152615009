.campaign-view .buttons-area.autopilot {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;

  border-radius: 8px;
  background: var(--Wallstreet-Black, #000d26);
  color: var(--Plus-White, #fffefc);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.campaign-view .buttons-area.autopilot svg {
  color: var(--Dream-Yellow);
}

.campaign-view .title-and-text b {
  font-weight: 500;
}

.campaign-view-lower-area {
  display: flex;
  flex-direction: column;
  padding: 16px 24px;
  padding-top: 0px;
  gap: 16px;
}

.campaign-view-lower-area .top-box p {
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
}

.campaign-view-lower-area .top-box p b {
  color: var(--Dark-Blue, #086788);
  font-family: 'Spline Sans';
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 40px; /* 125% */
}

.campaign-view-lower-area .top-box p small {
  display: block;
  color: var(--Dark-Blue, #086788);
  font-family: 'Spline Sans';
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
}

.campaign-view-lower-area .actions-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}

.campaign-view-lower-area .actions-row .buttons-left {
  display: flex;
  align-items: center;
  gap: 16px;
}

.campaign-view-lower-area .actions-row .settings-right > div {
  display: flex;
  padding: 0px 8px;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
  color: var(--Performa-Black, #152647);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  cursor: pointer;
}

.campaign-view-lower-area .actions-row .settings-right > div svg {
  color: #838b9b;
}

.campaign-view-lower-area .actions-row button {
  display: flex;
  padding: 8px 24px 8px 20px;
  justify-content: center;
  align-items: center;
  gap: 12px;

  border-radius: 8px;
  border: 1px solid var(--Grays-Light, #c1c5cd);
  background: var(--Plus-White, #fffefc);

  /* Container-shadow */
  box-shadow: 0px 1px 2px 0px rgba(21, 38, 71, 0.2);
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
}

.campaign-view-lower-area .actions-row button svg {
  color: var(--Light-Blue);
}

.campaign-view-lower-area .actions-row svg {
  height: 20px;
  width: 20px;
  color: var(--Light-Blue);
}

.campaign-view-lower-area .main-area {
  display: flex;
  align-items: flex-start;
  gap: 24px;
  flex: 1 0 0;
  align-self: stretch;
}

.campaign-view-lower-area .main-area .inbox-wrapper {
  flex: 1;
}

.campaign-view-lower-area .task-list-wrapper {
  width: 360px;
}

.campaign-view.first-loading .top-box {
  background: var(--Lisa-Yellow);
  border: var(--Lisa-Yellow);
}

.campaign-view.first-loading .top-box * {
  visibility: hidden;
}

.campaign-view.first-loading .actions-row button {
  background: var(--Lisa-Yellow);
  border: var(--Lisa-Yellow);
  color: var(--Lisa-Yellow);
  box-shadow: none;
}
.campaign-view.first-loading .actions-row button svg {
  color: var(--Lisa-Yellow);
}

.prospects-lower-area.embedded {
  padding-left: 0px;
  padding-right: 0px;
}

.prospects-lower-area {
  display: flex;
  padding: 10px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
}

.prospects-lower-area .logo-name {
  display: flex;
  width: 232px;
  align-items: center;
  gap: 12px;
}

.prospects-lower-area .logo-name .fake-pic svg {
  width: 32px;
  height: 32px;
}

.prospects-lower-area .logo-name img {
  width: 32px;
  height: 32px;
  border-radius: 26px;
}
.prospects-lower-area .logo-name {
  color: var(--Performa-Black, #152647);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
}

.dream-positive {
  background: linear-gradient(
      0deg,
      rgba(255, 214, 1, 0.2) 0%,
      rgba(255, 214, 1, 0.2) 100%
    ),
    #fff;
}

.processing-alert-wrapper {
  margin-bottom: 16px;
  margin-top: 6px;
  transition:
    opacity 0.5s ease-in-out,
    height 0.5s ease-in-out,
    margin 0.5s ease-in-out;
}

.processing-alert-wrapper.hidden {
  opacity: 0;
  height: 0px;
  margin: 0px;
}
.processing-alert-wrapper.visible {
  opacity: 1;
  height: 38px;
  margin-top: 16px;
  margin-bottom: 6px;
}
