.task-list-outer-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  border-radius: 8px;
  border: 1px solid var(--Grays-Light, #c1c5cd);
  box-shadow: 0px 1px 2px 0px rgba(21, 38, 71, 0.2);
  background: #fff;
}

.task-list-outer-wrapper.loading {
  background: var(--Lisa-Yellow, #f7f5e9);
  border-color: var(--Lisa-Yellow, #f7f5e9);
}

.task-list-outer-wrapper .remaining-tag {
  display: flex;
  width: 36px;
  height: 28px;
  padding-top: 1px;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  border-radius: 32px;
  background: var(--Light-Red, #f67e7d);
  color: #000;
  font-family: 'Spline Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 137.5% */
}

.task-list-outer-wrapper.loading * {
  visibility: hidden;
}

.task-list-wrapper {
  width: 100%;
}

.task-list-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
}

.task-list-wrapper .task-item:hover {
  background: #fafafa;
}

.task-item {
  display: flex;
  padding: 12px;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  border-bottom: 1px solid var(--Grays-Lighter, #e6e8eb);
  cursor: pointer;
}

.task-item img {
  border: 1px solid #e6e8eb;
  height: 32px;
  width: 32px;
  align-items: center;
  flex-shrink: 0;
  border-radius: 32px;
}

.task-item .fake-pic svg {
  width: 32px;
  height: 32px;
}

.task-list-wrapper .left-side {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex: 1 0 0;
}

.task-list-wrapper .left-side p {
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 171.429% */
}

.task-list-wrapper .left-side small {
  color: var(--Grays-Medium, #838b9b);
  font-family: 'Spline Sans';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
}

.task-list-wrapper .right-side span {
  display: flex;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.task-list-wrapper .right-side span.approval {
  border-radius: 4px;
  background: var(--Grays-Lightest, #f3f3f5);
}

.task-list-wrapper .right-side span.escalation {
  border-radius: 4px;
  background: var(--Light-Red, #f67e7d);
}

.approvals-wrapper,
.escalations-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
}

.approvals-wrapper .campaign-link-topper {
  display: flex;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;

  border-radius: 8px;
  background: var(--Lisa-Yellow, #f7f5e9);
}
.approvals-wrapper .campaign-link-topper p {
  color: #000;
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  flex: 1;
}

.approvals-wrapper .campaign-link-topper a {
  color: var(--Dark-Blue, #086788);
  text-align: right;
  font-family: 'Spline Sans';
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration: none;
}

.approvals-wrapper .heading,
.escalations-wrapper .heading {
  width: 100%;
}

.approvals-wrapper .heading h6 {
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 125% */
}

.approvals-wrapper .heading p {
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}

.approvals-wrapper .heading a {
  color: var(--Dark-Blue, #086788);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  text-decoration: none;
}

.approvals-wrapper .to-who-area {
  display: flex;
  padding: 12px 16px;
  align-items: center;
  gap: 16px;
  align-self: stretch;

  border: 1px solid var(--Grays-Lighter, #e6e8eb);
  background: var(--Grays-Lightest, #f3f3f5);
}

.approvals-wrapper .to-who-area .left-side {
  display: flex;
  align-items: center;
  gap: 12px;
  flex: 1 0 0;
}

.approvals-wrapper .to-who-area .left-side img,
.approvals-wrapper .to-who-area .left-side svg {
  width: 32px;
  height: 32px;
  border-radius: 32px;
}

.approvals-wrapper .to-who-area .left-side .title {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
}

.approvals-wrapper .to-who-area .left-side .title p:first-child {
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
}

.approvals-wrapper .to-who-area .left-side .title p:first-child small {
  color: var(--Pomona-Black, #5c6880);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}

.approvals-wrapper .to-who-area .left-side .title p:last-child {
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.approvals-wrapper .to-who-area .right-side a {
  color: var(--Dark-Blue, #086788);
  text-align: right;
  font-family: 'Spline Sans';
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration: none;
}

.responding-to-wrapper {
  display: flex;
  padding: 12px 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;

  border-radius: 12px;
  border: 1px solid var(--Grays-Light, #c1c5cd);
  background: #fff;
}

.responding-to-wrapper b {
  font-weight: 500;
}

.responding-to-wrapper {
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}

.responding-to-wrapper .header {
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 125% */
}

.responding-to-wrapper .message {
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-self: stretch;
}
.responding-to-wrapper hr {
  margin: 0px;
}

.staged-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch;

  border-radius: 12px;
  border: 1px solid var(--Light-Green, #64b6ac);
}

.staged-wrapper .banner {
  display: flex;
  padding: 8px 20px;
  align-items: center;
  align-self: stretch;

  background: var(
    --SDRs-Gradient,
    linear-gradient(131deg, #64b6ac 0.93%, #77d9cd 102.14%)
  );
}

.staged-wrapper .banner {
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

.staged-wrapper .banner b {
  font-weight: 500;
}

.staged-wrapper .message-wrapper {
  display: flex;
  padding: 12px 20px;
  flex-direction: column;
  gap: 12px;
  align-self: stretch;
  background: #fff;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}

.staged-wrapper .message-wrapper .body-area {
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-self: stretch;
}

.body-area hr {
  background: var(--Grays-Light, #c1c5cd);
  margin: 0px;
}

.staged-wrapper .message-wrapper .body-area .header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
}

.staged-wrapper .message-wrapper .body-area .header h6 {
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 125% */
}

.staged-wrapper .message-wrapper .body-area .header small {
  color: var(--Grays-Medium, #838b9b);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}

.message-wrapper .buttons-area {
  display: flex;
  padding: 24px 0px;
  justify-content: center;
  align-items: center;
  align-self: stretch;
}

.message-wrapper .body {
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}

.message-wrapper .buttons-area button:hover {
  background: white;
  color: var(--Wallstreet-Black, #000d26);
}

.message-wrapper .buttons-area button {
  display: flex;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  flex: 1 0 0;
  background: #fff;
  border: none;
  color: var(--Wallstreet-Black, #000d26);
  text-align: center;
  font-family: 'Spline Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 125% */
  border-radius: 0px;
}

.message-wrapper .buttons-area button:nth-child(2) {
  border-left: 1px solid var(--Grays-Light, #c1c5cd);
  border-right: 1px solid var(--Grays-Light, #c1c5cd);
}

.message-wrapper .buttons-area button:last-child {
  color: var(--Dark-Red, #931f1d);
}

.guidance-area {
  width: 100%;
}

.guidance-area .button-wrapper {
  display: flex;
  justify-content: flex-end;
  margin-top: 8px;
}

.guidance-area .button-wrapper button {
  display: flex;
  height: 48px;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: var(--Plus-White, #fffefa);
  font-family: 'Spline Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 125% */
}

.escalations-wrapper .options-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  border-radius: 12px;
  border: 1px solid var(--Grays-Light, #c1c5cd);
}

.escalations-wrapper .options-div .heading {
  display: flex;
  padding: 8px 20px;
  align-items: center;
  align-self: stretch;

  background: var(--Lisa-Yellow, #f0eddc);
  color: var(--Wallstreet-Black, #000d26);
  text-align: center;
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */

  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

.escalations-wrapper .options-div .button-wrapper {
  display: flex;
  padding: 12px 20px;
  gap: 12px;
  align-self: stretch;
  background: #fff;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}

.escalations-wrapper .options-div .button-wrapper button {
  display: flex;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  flex: 1 0 0;
  color: var(--Wallstreet-Black, #000d26);
  text-align: center;
  font-family: 'Spline Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 125% */
  background: #fff;
  border: none;
  border-radius: 0px;
}

.escalations-wrapper .options-div .button-wrapper button:first-child {
  border-right: 1px solid var(--Grays-Light, #c1c5cd);
}

.escalations-wrapper .options-div .button-wrapper button:last-child {
  color: var(--Dark-Red);
}

.task-list-wrapper h6 {
  display: flex;
  padding: 4px 16px;
  align-items: center;
  align-self: stretch;

  background: var(--Grays-Lightest, #f3f3f5);
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 171.429% */
}

.task-list-outer-wrapper .header {
  display: flex;
  padding: 12px 12px 12px 16px;
  align-items: center;
  align-self: stretch;
  gap: 12px;
}

.task-list-outer-wrapper .header h4 {
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 120% */
}

.task-list-outer-wrapper .view-all-wrapper {
  cursor: pointer;
  display: flex;
  padding: 8px 0px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  width: 100%;
  color: var(--Dark-Blue, #086788);
  text-align: center;
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 171.429% */
  background: rgba(7, 160, 195, 0.05);
}

.task-list-wrapper .empty-state span {
  font-size: 14px;
}

.task-list-wrapper .empty-state {
  padding: 12px 0px;
  border-radius: 0px;
  border: none;
}

.horn-holder {
  display: flex;
  width: 32px;
  height: 32px;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  border-radius: 32px;
  background: var(--Dream-Yellow);
}

.campaign-action b {
  font-weight: 500;
}

.task-list-page-lower {
  display: flex;
  align-items: flex-start;
  align-self: stretch;
}

.task-list-page-lower .left-panel {
  display: flex;
  width: 320px;
  padding: 16px 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;

  border-right: 1px solid var(--Grays-Lighter, #e6e8eb);
}

.task-list-page-lower .left-panel .actions-menu {
  display: flex;
  padding: 4px;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;

  border-radius: 8px;
  background: var(--Grays-Lighter, #e6e8eb);
  cursor: pointer;
}

.task-list-page-lower .left-panel .actions-menu > * {
  display: flex;
  padding: 12px 16px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.task-list-page-lower .left-panel .actions-menu > *.selected {
  border-radius: 4px;
  background: var(--Plus-White, #fffefc);

  /* Modal shadow */
  box-shadow:
    0px 8px 16px 0px rgba(6, 22, 54, 0.1),
    0px 2px 4px 0px rgba(0, 13, 38, 0.2);
}

.task-list-page-lower .left-panel .actions-menu > * span:last-child {
  display: flex;
  padding: 2px 4px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;

  border-radius: 4px;
  background: var(--Lisa-Yellow, #f7f5e9);
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.task-list-page-lower .right-panel {
  display: flex;
  padding: 16px 24px;
  flex-direction: column;
  align-items: stretch;
  gap: 10px;
  flex: 1 0 0;
}

.task-list-page-lower .right-panel-inner {
  display: flex;
  min-width: 768px;
  flex-direction: column;
  align-items: stretch;

  border-radius: 12px;
  border: 1px solid var(--Grays-Light, #c1c5cd);
}

.task-list-page-lower .right-panel-inner > *:first-child > h6 {
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

.task-list-page-lower .right-panel-inner h6 {
  display: flex;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;

  background: var(--Grays-Lightest, #f3f3f5);
  color: var(--Wallstreet-Black, #000d26);

  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 171.429% */
  cursor: pointer;
  user-select: none;
}

.task-list-page-lower .right-panel-inner h6 svg {
  width: 20px;
  height: 20px;
}

.task-list-page-lower .right-panel-inner h6.collapsed {
  border-bottom: 1px solid var(--Grays-Light, #c1c5cd);
}

.task-list-page-lower .right-panel-inner h6 .tag-and-text {
  display: flex;
  align-items: center;
  gap: 16px;
}

.task-list-page-lower .task-item {
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 153.846% */
  user-select: none;
}
.task-list-page-lower .task-item small {
  color: var(--Grays-Medium, #838b9b);
  font-family: 'Spline Sans';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
}

.task-list-page-lower .task-item .left-side {
  display: flex;
  align-items: center;
  gap: 12px;
  align-self: stretch;
  justify-content: space-between;
  width: 100%;
}

.task-list-page-lower .task-item .left-side span.view-span {
  color: var(--Dark-Blue, #086788);
  text-align: right;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
}

.task-list-page-lower
  .right-panel-inner
  > div:last-child
  > .task-item-outer-wrapper-page:last-child
  .task-item {
  border-bottom-right-radius: 12px;
  border-bottom-left-radius: 12px;
}

.task-list-page-lower .right-panel-inner > div:last-child h6.collapsed {
  border-bottom-right-radius: 12px;
  border-bottom-left-radius: 12px;
  border-bottom: none;
}

.task-list-page-lower .right-panel .empty-state {
  border: 0px;
  height: calc(100vh - 57px);
  justify-content: center;
}

.task-list-page-lower .right-panel .empty-state svg {
  color: var(--Light-Blue);
  width: 40px;
  height: 40px;
}

.task-list-page-lower .right-panel .empty-state > div {
  text-align: center;
}

.task-list-page-lower .right-panel .empty-state > div p {
  margin-top: 4px;
  color: var(--Performa-Black, #152647);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}

.fake-items {
  display: flex;
  width: 100%;
  flex-direction: column;
  border-radius: 12px;
  border: 1px solid var(--Grays-Light, #c1c5cd);
  position: relative;
}

.fake-items .fake-item {
  display: flex;
  height: 88px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
}

.fake-items .fake-item .fake-header {
  display: flex;
  height: 32px;
  padding: 4px 12px;
  align-items: center;
  flex-shrink: 0;
  align-self: stretch;

  background: var(--Grays-Lightest, #f3f3f5);
}

.fake-items .fake-item .fake-header + div {
  display: flex;
  height: 56px;
  padding: 8px 16px 12px 16px;
  align-items: center;
  gap: 12px;
  flex-shrink: 0;
  align-self: stretch;
}

.fake-items .fake-profile-pic {
  width: 32px;
  height: 32px;

  background: var(--Grays-Lightest, #f3f3f5);
  border-radius: 32px;
}

.fake-items .fake-item:nth-child(2) .fake-header {
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

.spinner-abs {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.spinner-abs > * {
  color: var(--Light-Blue);
}
