.main-dashboard {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 16px;
  flex: 1 0 0;
}

.main-dashboard .lower-area {
  display: flex;
  gap: 16px;
  align-self: stretch;
}

.main-dashboard .lower-area .feed-outer-wrapper {
  flex: 1;
}

.main-dashboard .lower-side-wrapper {
  width: 325px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 16px;
}

.main-dashboard .lower-area .task-list-wrapper .empty-state {
  border: none;
  text-align: center;
}

.new-leads {
  width: 100%;
}

.new-leads.loading {
  background: var(--Lisa-Yellow, #f7f5e9);
  border-color: var(--Lisa-Yellow, #f7f5e9);
}

.new-leads.loading * {
  visibility: hidden;
}

.new-leads .status-tag {
  display: flex;
  padding: 4px 8px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}

.new-leads .semi-positive {
  background: linear-gradient(
      0deg,
      rgba(7, 160, 195, 0.3) 0%,
      rgba(7, 160, 195, 0.3) 100%
    ),
    #fff;
}

.new-leads .neutral {
  background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.1) 0%,
      rgba(0, 0, 0, 0.1) 100%
    ),
    #fff;
}
.new-leads .positive {
  background: linear-gradient(
      0deg,
      rgba(100, 182, 172, 0.4) 0%,
      rgba(100, 182, 172, 0.4) 100%
    ),
    #fff;
}

.new-leads .negative {
  background: linear-gradient(
      0deg,
      rgba(246, 126, 125, 0.4) 0%,
      rgba(246, 126, 125, 0.4) 100%
    ),
    #fff;
}

.new-leads-table .status-wrapper > div {
  display: flex;
  justify-content: flex-end;
}

.new-leads-table tr:hover {
  background: #fafafa;
  cursor: pointer;
}

.new-leads .empty-state {
  border: none;
}

.new-leads .empty-state svg {
  width: 48px;
  height: 48px;
  color: var(--Light-Blue);
}

.new-leads .empty-state p {
  color: var(--Performa-Black, #152647);
  text-align: center;
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.lower-side-wrapper h4 {
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 120% */
}

.new-leads {
  display: flex;
  width: 325px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 8px;
  border: 1px solid var(--Grays-Light, #c1c5cd);
  box-shadow: 0px 1px 2px 0px rgba(21, 38, 71, 0.2);
  background: #fff;
}

.new-leads h4 {
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 120% */
  display: flex;
  height: 64px;
  padding: 12px 12px 12px 16px;
  align-items: center;
  align-self: stretch;
}

.new-leads .new-lead {
  display: flex;
  padding: 8px 16px;
  align-items: center;
  gap: 8px;
  align-self: stretch;

  border-top: 1px solid var(--Grays-Lighter, #e6e8eb);
  background: #fff;
}

.new-leads .new-lead .left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex: 1 0 0;
}

.new-leads .new-lead .left p {
  color: var(--Performa-Black, #152647);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}

.new-leads .new-lead .left small {
  color: var(--Grays-Medium, #838b9b);
  font-family: 'Spline Sans';
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 166.667% */
}

.new-leads .view-all-wrapper {
  display: flex;
  padding: 8px 0px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  color: var(--Dark-Blue, #086788);
  border-top: 1px solid var(--Grays-Lighter, #e6e8eb);
  background: rgba(7, 160, 195, 0.05);
  text-align: center;
  font-family: 'Spline Sans';
  font-size: 14px;
  width: 100%;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 171.429% */
}

.new-leads .new-lead .fake-pic svg {
  width: 32px;
  height: 32px;
}

.new-leads .new-lead .fake-pic,
.new-leads .new-lead img {
  width: 32px;
  height: 32px;
  border-radius: 32px;
}

.main-dashboard .chart-management {
  display: flex;
  padding: 0px 24px 0px 24px;
  flex-direction: column;
  gap: 24px;
  align-self: stretch;
}

.main-dashboard .selected-and-toggle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}

.selected-datasets {
  display: flex;
  align-items: center;
  gap: 12px;
}

.selected-datasets .dataset-choice,
.custom-tooltip {
  display: flex;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;

  border-radius: 24px;
  background: var(--Wallstreet-Black, #000d26);
  color: var(--Plus-White, #fffefc);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
}

.custom-tooltip {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.custom-tooltip .value-wrapper {
  display: flex;
  align-items: center;
  gap: 8px;
}

.custom-tooltip .dot {
  width: 10px;
  height: 10px;
  border-radius: 36px;
}

.selected-and-toggle .interval-toggle {
  display: flex;
  padding: 4px;
  align-items: center;

  border-radius: 8px;
  background: var(--Grays-Lighter, #e6e8eb);
  cursor: pointer;
}

.selected-and-toggle .interval-toggle div {
  display: flex;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.selected-and-toggle .interval-toggle div.active {
  border-radius: 4px;
  background: var(--Plus-White, #fffefc);

  /* Modal shadow */
  box-shadow:
    0px 8px 16px 0px rgba(6, 22, 54, 0.1),
    0px 2px 4px 0px rgba(0, 13, 38, 0.2);
}

.selected-datasets .dataset-choice {
  display: flex;
  align-items: center;
}

.main-dashboard .top-box.prospects.selected {
  background: rgba(7, 160, 195, 0.05);
  border: 1px solid var(--Light-Blue, #07a0c3);
}

.main-dashboard .top-box.emails.selected {
  border-radius: 8px;
  border: 1px solid var(--Light-Red, #f67e7d);
  background: rgba(246, 126, 125, 0.05);
}

.main-dashboard .top-box.replies.selected {
  border-radius: 8px;
  border: 1px solid var(--Light-Green, #64b6ac);
  background: rgba(100, 182, 172, 0.05);
}

.main-dashboard .top-box.meetings.selected {
  border-radius: 8px;
  border: 1px solid #8338ec;
  background: rgba(131, 56, 236, 0.05);
}

.selected-datasets .prospects .point {
  background: #07a0c3;
}

.selected-datasets .emails .point {
  background: #f67e7d;
}

.selected-datasets .replies .point {
  background: #64b6ac;
}

.selected-datasets .meetings .point {
  background: #8338ec;
}

.selected-datasets .point {
  width: 12px;
  height: 12px;
  border-radius: 36px;
}

.main-dashboard .top-box {
  cursor: pointer;
}

.chart-management.loading {
  border-radius: 8px;
  background: var(--Lisa-Yellow, #f7f5e9);
  height: 304px;
}

.chart-management.loading * {
  visibility: hidden;
}

.all-loaded {
  display: flex;
  height: 36px;
  padding: 8px 0px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;

  border-top: 1px solid var(--Grays-Lighter, #e6e8eb);
  background: var(--Grays-Lightest, #f3f3f5);
  color: var(--Pomona-Black, #5c6880);
  text-align: center;
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 171.429% */
}

.feed-empty-state {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
  flex: 1 0 0;
  align-self: stretch;
  padding: 24px;
  padding-bottom: 64px;
}

.feed-empty-state svg {
  width: 40px;
  height: 40px;
  color: var(--Light-Blue);
}

.feed-empty-state > div {
  text-align: center;
}

.lower-area .feed-outer-wrapper {
  margin-bottom: 164px;
}

.lower-area .view-all-wrapper {
  cursor: pointer;
}

.new-lead {
  cursor: pointer;
}

.all-leads {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;

  border-radius: 12px;
  border: 1px solid var(--Grays-Light, #c1c5cd);
  background: transparent;
  width: 100%;
}

.all-leads .lead-item {
  display: flex;
  padding: 8px 16px;
  align-items: center;
  gap: 12px;
  align-self: stretch;

  border-top: 1px solid var(--Grays-Lighter, #e6e8eb);
  background: #fff;
}

.all-leads .lead-item img {
  width: 32px;
  height: 32px;
  border-radius: 32px;
}

.all-leads .lead-item:first-child {
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

.all-leads .lead-item:last-child {
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}

.all-leads .status-wrapper {
  display: flex;
  width: 112px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.all-leads .lead-item > small:last-child {
  color: var(--Pomona-Black, #5c6880);
  font-family: 'Spline Sans';
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 153.846% */
  width: 120px;
  text-align: right;
}
