.prospects-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  flex: 1 0 0;
  align-self: stretch;
}

.prospects-content h6 {
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 120% */
}

.prospect-search-options {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 24px;
  align-self: stretch;
}

.prospect-search-options .option {
  display: flex;
  width: 320px;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 48px;

  border-radius: 16px;
  border: 1px solid var(--Grays-Light, #c1c5cd);
  background: #fff;

  /* Container-shadow */
  box-shadow: 0px 1px 2px 0px rgba(21, 38, 71, 0.2);
}

.prospect-search-options .option .title {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
}

.prospect-search-options .option .svg-frame {
  display: flex;
  padding: 12px;
  justify-content: center;
  align-items: center;

  border-radius: 4px;
  background: var(--Grays-Lightest, #f3f3f5);
}

.prospect-search-options .option svg {
  color: var(--Light-Blue);
  width: 27px;
  height: 29px;
}

.prospect-search-options .option .title > p {
  flex: 1 0 0;
  align-self: stretch;

  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px; /* 160% */
}

.prospect-search-options .option > button {
  display: flex;
  height: 48px;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;

  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.prospect-search-options .option:hover {
  border-radius: 16px;
  border: 1px solid var(--Grays-Light, #c1c5cd);
  background: var(--Grays-Lightest, #f3f3f5);

  /* Modal shadow */
  box-shadow:
    0px 8px 16px 0px rgba(6, 22, 54, 0.1),
    0px 2px 4px 0px rgba(0, 13, 38, 0.2);
}

.prospect-search-options .option:hover button {
  border-radius: 8px;
  border: 1px solid var(--Wallstreet-Black, #000d26);
  background: var(--Wallstreet-Black, #000d26);
  color: white;
}

.prospect-search-options .option:hover .svg-frame {
  border-radius: 4px;
  background: var(--Grays-Lighter, #e6e8eb);
}
.job-titles-tab,
.agent-flexibility-tab {
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-self: stretch;
}
.agent-flexibility-tab {
  gap: 16px;
}

.known-job-title-modal .tab-lower {
  margin-top: 32px;
}

.job-titles-tab .range-flex {
  align-self: flex-start;
}

.agent-flexibility-tab div > label {
  margin-bottom: 6px;
}

.agent-flexibility-tab label span {
  font-weight: 400;
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}

.test-prospect-search {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  align-self: stretch;
}

.test-prospect-search .job-titles {
  display: flex;
  padding: 16px;
  align-items: flex-start;
  align-content: flex-start;
  gap: 4px 8px;
  align-self: stretch;
  flex-wrap: wrap;

  border-radius: 8px;
  background: var(--Lisa-Yellow, #f0eddc);
}

.test-prospect-search .job-titles {
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}

.test-prospect-search .more-link::before {
  content: ', ';
}

.test-prospect-search .more-link {
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  cursor: pointer;
}

.test-prospect-search .test-company-wrapper {
  display: flex;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
}

.test-prospect-search .test-company-wrapper > *:first-child {
  flex: 1;
  height: 48px;
}

.test-prospect-search .known-orgs-section {
  align-self: stretch;
}

.test-prospect-search .test-company-wrapper button {
  display: flex;
  height: 48px;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.big-react-select {
  min-height: 48px !important;
}

.job-titles-section {
  align-self: stretch;
}

.known-orgs-section .loading,
.job-titles .loading {
  display: flex;
  justify-content: center;
  width: 100%;
}

.prospect-test-area {
  align-self: stretch;
}

.prospect-search-table {
  width: 100%;
}

.prospect-search-table-wrapper {
  border-radius: 8px;
  border: 1px solid var(--Grays-Lighter, #e6e8eb);
  background: #fff;
  margin-top: 16px;
}

.prospect-search-table td,
.prospect-search-table th {
  padding: 8px;
}

.prospect-search-table td:first-child,
.prospect-search-table th:first-child {
  padding-left: 16px;
}

.prospect-search-table td:first-child svg {
  width: 22px;
  height: 22px;
  color: var(--Dark-Blue, #086788);
}

.prospect-search-table tr {
  border-bottom: 1px solid var(--Grays-Lighter, #e6e8eb);
}

.prospect-search-table tbody tr:last-child {
  border-bottom: 0px;
}

.ps-wrapper {
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;

  border-radius: 12px;
  border: 1px solid var(--Grays-Light, #c1c5cd);
  background: #fff;
}

.ps-wrapper .ps-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}

.ps-header h6 {
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 125% */
}

.ps-header span {
  color: var(--Dark-Red, #931f1d);
  font-family: 'Spline Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;
}

.ps-wrapper .info-bar {
  display: flex;
  padding: 16px;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  border: 1px solid var(--Grays-Light, #c1c5cd);
  background: var(--Plus-White, #fffefa);
  gap: 32px;
  align-self: stretch;
}

.ps-wrapper .info-bar .left {
  flex: 1;
}

.ps-wrapper .info-bar .right {
  width: 200px;
}

.ps-wrapper .info-bar .left label {
  align-self: stretch;

  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
}

.ps-wrapper .info-bar .left p {
  color: var(--Performa-Black, #152647);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}

.ps-wrapper .info-bar button {
  color: var(--Dark-Blue, #086788);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration: none;
}

.ag-form {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.ag-form .steps-wrapper {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.ag-form .steps-wrapper .step-input {
  display: flex;
  padding: 16px;
  flex-direction: column;
  gap: 16px;
  align-self: stretch;

  border-radius: 16px;
  border: 1px solid var(--Grays-Light, #c1c5cd);
  background: #fff;
}

.ag-form .steps-wrapper .step-input label {
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
}

.prospect-test-area .prospects {
  display: flex;
  flex-direction: column;
  margin-top: 12px;
  gap: 12px;
}

.prospect-test-area .prospects .prospect-wrapper {
  display: flex;
  padding: 16px;
  align-items: center;
  gap: 16px;
  align-self: stretch;

  border-radius: 16px;
  border: 1px solid var(--Grays-Light, #c1c5cd);
  background: #fff;
}

.prospect-test-area .prospects .prospect-wrapper svg {
  width: 22px;
  height: 22px;
  color: var(--Dark-Blue, #086788);
  margin-top: 6px;
}
.prospect-test-area .prospects .prospect-wrapper .main-text-area {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.prospect-test-area .prospects .prospect-wrapper .main-text-area p:first-child {
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.prospect-test-area .prospects .prospect-wrapper .main-text-area p.reason {
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.job-titles-generate-area {
  display: flex;
  padding: 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
  align-self: stretch;

  border-radius: 12px;
  background: var(--Lisa-Yellow, #f0eddc);
}

.prospect-search-wrapper {
  display: flex;
  padding: 24px 32px;
  padding-top: 0px;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  flex: 1 0 0;
  align-self: stretch;
}

.prospect-search-wrapper h6 {
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 120% */
}

.ps-options-wrapper {
  display: flex;
  padding: 24px 32px;
  padding-top: 0px;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  align-self: stretch;
}

.ps-options-wrapper .pso-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}

.ps-options-wrapper .pso-header h6 {
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 120% */
}

.ps-options-wrapper .pso-header .actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
}

.ps-options-wrapper .pso-header .actions button {
  display: flex;
  padding: 8px 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.ps-options-wrapper .pso-header .actions .btn-primary {
  color: var(--Plus-White, #fffefc);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
}

.ps-options-wrapper .pso-header .actions .btn-outline-primary {
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
}
