// custom.scss

// Import Bootstrap functions and variables
@import '~bootstrap/scss/functions';

$primary: rgba(0, 15, 35, 1); // Your custom primary
$secondary: rgba(255, 208, 4, 1);
$theme-colors: (
  'primary': $primary,
  'secondary': $secondary,
  'info': rgba(11, 150, 187, 1),
);

@import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.scss';

@import '~bootstrap/scss/variables';

// Override Bootstrap variables

// Import Bootstrap to apply the overrides
@import '~bootstrap/scss/bootstrap';
