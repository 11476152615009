.highlighted-email-body {
  color: var(--Performa-Black, #152647);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px; /* 142.857% */
}
.highlighted-email-body .highlight {
  border-radius: 4px;
  opacity: 1;
  background: rgba(255, 202, 8, 0.5);
  padding: 4px;
  cursor: pointer;
}

.he-spinner-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 128px;
  margin-top: 12px;
  width: 100%;
  border-radius: 12px;
  opacity: 0.6;
  background: var(--Very-Light-Grey);
}
