.sequences-wrapper {
  display: flex;
  flex-direction: column;
}

.sequence {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  padding: 16px;
  border: 1px solid var(--royal-purple);
  background: #fff;
  border-radius: 5px;
}

.sequence h4 {
  margin-bottom: 0px;
  text-transform: uppercase;
  color: var(--royal-purple);
  cursor: pointer;
}

.wide-add-button {
  margin-top: 10px;
  width: 100%;
}

.email-editor {
  display: flex;
  padding: 16px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  cursor: pointer;
  border-radius: 8px;
  border: 1px solid var(--Grays-Light, #c1c5cd);
  background: var(--Plus-White, #fffefc);
}

.email-editor .ql-container {
  height: 200px;
}

.sequences-page {
  max-width: 900px;
}

.sequences-wrapper .loading-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  color: var(--royal-purple);
}

.sequences-inner {
  padding: 12px;
}

.email-editor .ql-container {
  height: 200px;
}

.drag-handle {
  cursor: grab !important;
}

.edit-sequence-page {
  max-width: 960px;
}

.edit-sequence-form {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.edit-sequence-form .sequence-info {
  display: flex;
  flex-direction: column;
  gap: 12px;
  background: white;
  padding: 16px;
  border: 1px solid var(--Light-Grey);
}

.edit-sequence-form small {
  display: block;
  margin-top: 6px;
}

.email-editor {
  border-radius: 6px;
}

.email-editor.email-expanded {
  background: white;
}

.email-editor .form-inner {
  padding: 8px 0px;
}

.email-editor .form-inner .wait-group {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 12px;
}

.email-editor .form-inner .wait-group label {
  margin-bottom: 0px;
}

.email-editor .form-inner .wait-group input {
  width: 64px;
}

.email-editor textarea {
  resize: none;
  height: 220px;
}

.email-editor small.description {
  margin-bottom: 6px;
}

.email-editor .email-actions {
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
  gap: 8px;
}

.email-editor .label-wrapper {
  display: flex;
  align-items: center;
  gap: 12px;
  flex: 1 0 0;
}

.edit-sequence-form .wide-add-button {
  margin-top: -16px;
}

.actions-line {
  display: flex;
  justify-content: flex-end;
  margin-top: 32px;
}

.email-sample-form {
  padding: 16px;
  border: 1px solid var(--Light-Grey);
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.email-sample-form label:first-child {
  font-weight: 500;
}

.prospect-source-switch {
  display: flex;
  gap: 16px;
}

.email-sample-form .button-wrapper {
  display: flex;
  justify-content: flex-end;
}

.sequence-list {
  background: var(--very-light-grey);
  padding: 16px;
}

.sequence-list-page {
  width: 960px;
}

.save-email-button-wrapper {
  display: flex;
  justify-content: flex-end;
  background: var(--very-light-grey);
  margin-bottom: 32px;
  padding: 16px;
  margin-top: -16px;
}

.subject-area {
  display: flex;
  align-items: center;
  gap: 26px;
  padding: 12px 0px;
}

.subject-area > div:first-child {
  width: 220px;
}

.subject-area > div:last-child {
  flex: 1;
}

.save-email-button-wrapper .loading-view > *:first-child {
  margin-right: 8px;
}

.emails-wrapper .subject-editor {
  margin-bottom: 8px;
}

.guidance.subject {
  margin-bottom: 12px;
}

.guidance-wrapper > p {
  margin-bottom: 4px;
  color: var(--Performa-Black, #152647);
  font-family: 'Spline Sans';
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 120% */
}

.emails-wrapper .email .left-side {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.email-status-wrapper {
  padding: 8px 0px;
}
.email-status-wrapper .email-status-loading {
  color: var(--Light-Blue);
}

.email-editor .email-name-label {
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 0px;
}

.email-editor svg {
  color: var(--Light-Blue);
}
.email-editor.draft svg {
  color: var(--Pomona-Black, #5c6880);
}

.email-editor .icon-area {
  display: flex;
  align-items: center;
  gap: 6px;
}

.add-email-wrapper {
  display: flex;
  justify-content: center;
}

.add-email-wrapper button {
  display: flex;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  font-family: 'Spline Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.sequence-editing-area .loading-state {
  display: flex;
  padding: 24px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  flex: 1 0 0;
  align-self: stretch;

  border-radius: 8px;
  background: var(--Lisa-Yellow, #f0eddc);
}

.sequence-editing-area > .set > div:first-child,
.prospect-choose-area .set {
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-self: stretch;
  margin-bottom: 12px;
}

.sequence-editing-area .set > div > h5,
.prospect-choose-area .set h5 {
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px; /* 140% */
}

.sequence-editing-area .set > div > p,
.prospect-choose-area .set p {
  color: var(--Performa-Black, #152647);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}

.email-editor {
  margin-bottom: 0px;
}

.email-list-form .dream-list {
  display: flex;
  padding: 12px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  align-self: stretch;

  border-radius: 12px;
  border: 1px solid var(--Grays-Light, #c1c5cd);
  background: #fff;
}

.email-edit-modal {
  display: flex;
  align-items: flex-start;
  gap: 32px;
  flex: 1 0 0;
  align-self: stretch;
  height: 100%;
}

.email-edit-modal .form-side {
  display: flex;
  flex-direction: column;
  gap: 24px;
  flex: 1 0 0;
  height: 100%;
}

.email-edit-modal .sample-area {
  display: flex;
  width: 400px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
}

.email-edit-modal .sample-area .audience-edit-wrapper {
  display: flex;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  flex: 1 0 0;
  align-self: stretch;

  border-radius: 8px;
  border: 1px solid var(--Grays-Light, #c1c5cd);
  background: #fff;
}

.email-edit-modal .sample-area .sample-display-area {
  display: flex;
  padding: 20px;
  flex-direction: column;

  align-items: center;
  gap: 16px;
  flex: 1 0 0;
  align-self: stretch;

  border-radius: 8px;
  border: 1px solid var(--Grays-Light, #c1c5cd);
  background: var(--Lisa-Yellow, #f0eddc);
}

.email-edit-modal .email-form-wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-self: stretch;
  height: 100%;
}

.email-edit-modal .label-like {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
}

.email-edit-modal .label-like span {
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
}

.email-edit-modal .label-like label {
  color: var(--Wallstreet-Black, #000d26);
  text-align: center;
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}

.email-edit-modal .subject-area {
  margin-top: -16px;
}
.email-edit-modal .spacer-sorry-matt {
  height: 1px;
  align-self: stretch;
  background: var(--Grays-Light, #c1c5cd);
}

.email-edit-modal .body-area {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-self: stretch;
}

.email-edit-modal .body-area textarea {
  flex: 1;
}

.email-edit-modal .body-area .heading {
  display: flex;
  align-items: flex-end;
  gap: 4px;
  align-self: stretch;
  margin-bottom: 12px;
}

.email-edit-modal .body-area .heading > div:first-child {
  flex: 1;
}

.email-edit-modal .body-area .generate-button-wrapper {
  display: flex;
  justify-content: flex-end;
}

.email-edit-modal .body-area .generate-button-wrapper button {
  display: flex;
  height: 40px;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  color: var(--Plus-White, #fffefa);
  font-family: 'Spline Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.sample-area .sample-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  align-self: stretch;
  width: 100%;
}

.sample-area .sample-header span {
  color: var(--Dark-Blue, #086788);
  font-family: 'Spline Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 125% */
  cursor: pointer;
}

.audience-edit-wrapper .dream-side-modal-form {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.audience-edit-wrapper .button-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
}

.audience-edit-wrapper .button-wrapper button {
  display: flex;
  height: 40px;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  font-size: 16px;
  font-weight: 500;
}

.sample-display-area .email-body-preview {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.sample-display-area .email-preview-area {
  display: flex;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  flex: 1 0 0;
  align-self: stretch;
}

.email-preview-wrapper .email-subject-preview {
  display: flex;
  align-items: flex-start;
  gap: 7px;
}

.sample-display-area .loading-spinner {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.info-expander-wrapper {
  height: 100%;
}

.message-wrapper {
  display: flex;
  align-items: stretch;
  align-self: stretch;
  width: 100%;
  background: rgba(243, 243, 245, 0.5);
  max-height: calc(100vh - 151px);
}

.message-wrapper > .left-side {
  display: flex;
  width: 400px;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;

  border-right: 1px solid var(--Grays-Lighter, #e6e8eb);
  overflow-y: scroll;
}

.message-wrapper > .right-side {
  flex: 1;
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  flex: 1 0 0;
  align-self: stretch;

  background: var(--Grays-Lightest, #f3f3f5);
  overflow-y: scroll;
}

.tab-content.messaging {
  padding: 0px !important;
  flex: 1;
  display: flex;
  align-items: stretch;
  align-self: stretch;
}

.tab-content.messaging .step-content {
  height: 100%;
  flex: 1;
  display: flex;
  align-items: stretch;
}

.add-email-wrapper {
  width: 100%;
}

.add-email-wrapper button {
  display: flex;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  width: 100%;
  color: var(--Plus-White, #fffefc);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.email-editor.active {
  border-radius: 8px;
  border: 1px solid var(--Wallstreet-Black, #000d26);
  background: var(--Dream-Yellow, #ffd601);
  position: relative;
}

.email-editor.active > *:first-child {
  position: absolute;
  right: -9px;
  top: 20px;
}

.email-editor.active svg {
  color: var(--Wallstreet-Black);
}

.email-sample-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  flex: 1 0 0;
  align-self: stretch;
}

.email-sample-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}
.email-sample-header h5 {
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px; /* 140% */
}

.email-sample-header .button-wrapper {
  display: flex;
  align-items: center;
  gap: 12px;
}

.email-sample-header button.generate-button {
  display: flex;
  height: 40px;
  padding: 12px 20px;
  justify-content: center;
  align-items: center;
  gap: 8px;

  border-radius: 8px;
  border: 1px solid var(--Wallstreet-Black, #000d26);
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.email-sample-header button.save-and-add-button {
  display: flex;
  height: 40px;
  padding: 12px 20px;
  justify-content: center;
  align-items: center;
  gap: 8px;

  border-radius: 8px;
  border: 1px solid var(--Wallstreet-Black, #000d26);
  background: var(--Wallstreet-Black, #000d26);
  color: var(--Plus-White, #fffefc);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.email-sample-area {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid var(--Grays-Light, #c1c5cd);
  background: #fff;
}

.email-sample-area .email-sample-top-options {
  display: flex;
  padding: 12px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;

  border-bottom: 1px solid var(--Grays-Lighter, #e6e8eb);
}

.email-sample-area .save-prompt-button {
  display: flex;
  height: 36px;
  padding: 10px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  color: white;
  font-family: 'Spline Sans';
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.email-sample-area .customize-prompt-button,
.email-sample-area .customize-prompt-button:hover {
  display: flex;
  height: 36px;
  padding: 10px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;

  border-radius: 8px;
  background: #fff;
  border: 1px solid var(--Grays-Light, #c1c5cd);
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.email-sample-area .customize-prompt-button svg {
  color: var(--Wallstreet-Black, #000d26);
}

.email-sample-main-area {
  display: flex;
  justify-content: center;
  align-items: stretch;
  gap: 10px;
  align-self: stretch;
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  flex: 1;
}

.prompt-view-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: stretch;
  flex: 1;
}

.prompt-view-wrapper input,
.prompt-view-wrapper textarea {
  border: none;
  display: flex;
  padding: 16px 32px !important;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 0px !important;
}

.prompt-view-wrapper > *:last-child {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.prompt-view-wrapper > *:last-child > textarea {
  flex: 1;
}

.prompt-view-wrapper label {
  font-weight: 500;
  display: flex;
  padding: 16px 24px;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 125% */
  border-bottom: 1px solid var(--Grays-Medium, #838b9b);
}

.email-sample-area .multi-right-buttons {
  display: flex;
  gap: 8px;
}

.generated-email-view-wrapper {
  width: 100%;
}

.generated-email-view-wrapper .empty-state {
  border-radius: 0px;
  border: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.generated-email-view-wrapper .empty-state > div {
  text-align: center;
}

.generated-email-view-wrapper .empty-state p {
  font-weight: 400;
  font-size: 14px;
  margin-top: 6px;
  width: 391px;
}

.prospect-in-select {
  display: flex;
  align-items: center;
  gap: 12px;
}

.prospect-in-select img,
.prospect-in-select svg {
  width: 32px;
  height: 32px;
  border-radius: 32px;
}

.prospect-in-select {
  font-size: 14px;
}

.message-wrapper .react-select {
  width: 100%;
}

.message-wrapper .react-select__control {
  min-height: 52px;
  border-radius: 12px;
}

.prospect-choose-area {
  width: 100%;
}

.email-sample-main-area .progress {
  border-radius: 0px;
}

.progress-bar {
  background: var(--Light-Blue);
}

.generated-email-view-wrapper .email-to-show {
  padding: 32px 64px;
}

.prompt-edit-outer-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
  align-self: stretch;
}

.prompt-edit-outer-wrapper .email-research-points {
  display: flex;
  padding: 12px 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;

  background: linear-gradient(
      0deg,
      rgba(243, 243, 245, 0.5) 0%,
      rgba(243, 243, 245, 0.5) 100%
    ),
    #fff;
}

.prompt-edit-outer-wrapper .prompt-view-wrapper {
  display: flex;
  padding: 0px 24px;
  flex-direction: column;
  flex: 1 0 0;
  align-self: stretch;
}

.email-research-points .header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
}

.email-research-points .research-points-list {
  display: flex;
  align-items: center;
  gap: 12px;
  flex-wrap: wrap;
}

.email-research-points .research-points-list .research-point-button {
  display: flex;
  min-height: 40px;
  padding: 8px 16px 8px 8px;
  align-items: center;
  gap: 8px;

  border-radius: 8px;
  border: 1px solid var(--Grays-Light, #c1c5cd);
  background: #fff;
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.email-research-points .research-points-list .research-point-button svg {
  color: var(--Light-Red);
  width: 24px;
  height: 24px;
}

.email-research-points .research-points-list .research-point-button.active {
  font-weight: 500;
}
.email-research-points .research-points-list .research-point-button.active svg {
  color: var(--Light-Green);
}

.edit-research-point-modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 24px;
  align-self: stretch;
}

.dream-side-modal-form .switch-group {
  display: flex;
  align-items: center;
  gap: 16px;
  align-self: stretch;
}

.dream-side-modal-form .form-switch .form-check-input {
  height: 42px;
  width: 64px;
  cursor: pointer;
  margin-top: 0px;
}

.dream-side-modal-form .form-switch .form-check-input:checked {
  background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27%23ffd601%27/%3e%3c/svg%3e') !important;
}

.dream-side-modal-form .edit-research-point-modal hr {
  background: var(--Grays-Light, #c1c5cd);
}

.zombie textarea {
  margin-top: 8px !important;
}
.zombie:not(.active) {
  opacity: 0.5;
}
.sequence-editing-area {
  width: 100%;
}

.sequence-editing-area .loading-state {
  width: 100%;
}

.fake-datapoint {
  background: var(--Lisa-Yellow);
  width: 100px;
  height: 40px;
  border-radius: 12px;
}

.delete-button {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
}

.delete-button svg {
  width: 24px;
  height: 24px;
  color: #5c6880;
  margin-top: -2px;
}

.further-email-options {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  align-self: stretch;
}

.further-email-options .right-side {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 24px;
  color: var(--Performa-Black, #152647);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}

.further-email-options .right-side > *:first-child {
  display: flex;
  align-items: center;
  gap: 8px;
}

.further-email-options .right-side > *:first-child input {
  display: flex;
  width: 60px;
  padding: 4px 12px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;

  border-radius: 8px;
  border: 1px solid var(--Grays-Light, #c1c5cd);
  background: #fff;
  color: var(--Performa-Black, #152647);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}

.further-email-options .right-side > *:last-child {
  display: flex;
  align-items: center;
  gap: 12px;
}

.further-email-options .transparent {
  opacity: 0.5;
}

.draft-icon {
  color: #838b9b;
}
