.step-one-form {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.step-one-form .use-case-type-selectors {
  display: flex;
  align-items: stretch;
  gap: -1px;
  align-self: stretch;

  border-radius: 16px;
  background: #fff;
}

.step-one-form .use-case-type-selectors > .type {
  display: flex;
  padding: 20px 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  flex: 1 0 0;
  border: 1px solid var(--Grays-Light, #c1c5cd);

  cursor: pointer;
}

.step-one-form .use-case-type-selectors > .type:first-child {
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
}

.step-one-form .use-case-type-selectors > .type:last-child {
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
}

.step-one-form .use-case-type-selectors > .type:nth-child(2) {
  border-left: none;
  border-right: none;
}

.step-one-form .use-case-type-selectors > .type.active:nth-child(2) {
  border-color: var(--Wallstreet-Black, #000d26);
  border-right-width: 1px;
  border-left-width: 1px;
  border-right-style: solid;
  border-left-style: solid;
}

.use-case-type-selectors {
  margin-top: 4px;
}

.use-case-type-selectors .type.active {
  border: 1px solid var(--Wallstreet-Black, #000d26);
  background: var(--Dream-Yellow, #ffd601);
}

.use-case-type-selectors .type.active svg,
.use-case-type-selectors .type.active path {
  fill: black;
}

.use-case-type-selectors .type p {
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.search-type-outer-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
}

.search-type-outer-wrapper h5 {
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px; /* 160% */
}

.search-type-outer-wrapper .icon-wrapper {
  height: 32px;
}

.search-type-outer-wrapper > * {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-self: stretch;
}

.search-type-outer-wrapper .basic-section .search-type-select {
  display: flex;
  align-items: stretch;
  align-content: flex-start;
  gap: 12px;
  align-self: stretch;
  flex-wrap: wrap;
}

.search-type-select > * {
  display: flex;
  width: 262px;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;

  border-radius: 8px;
  border: 1px solid var(--Grays-Light, #c1c5cd);
  background: #fff;
}

.search-type-select > .option-wrapper svg {
  color: var(--Light-Blue);
}

.search-type-select .option-wrapper {
  cursor: pointer;
}

.search-type-select .option-wrapper .option-name {
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
}

.search-type-select .long-description {
  color: var(--Performa-Black, #152647);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}

.search-type-select .option-wrapper.active {
  border-radius: 8px;
  border: 1px solid var(--Wallstreet-Black, #000d26);
  background: var(--Dream-Yellow, #ffd601);
}

.search-type-select .option-wrapper.active svg {
  color: var(--Wallstree-Black);
}

.options-tab-content-header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
}

.funnel-create-modal .errors-area {
  margin-bottom: 24px;
}
